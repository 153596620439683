<template>
  		<CModal
        title="Añadir nota"
      	color="primary"
      	:show.sync="openModalAddNote"
      	class="modal-sm"
      	style="z-index: 9999999999999 !important; position: absolute;"
        :closeOnBackdrop="false"
        >
<div slot="header" class="w-100" style="display:flex; justify-content:space-between; align-items:center;">
        <h5>Añadir nota</h5>
        <CButton
             @click="close()"
        >
        X
        </CButton>
      </div>
    <CRow class="py-4 px-3">
      <CCol col="12">
        <h6 class="mb-3">Agrega una nota a esta Atención</h6>
      </CCol>
      <CCol col="12">
        <CInput
          placeholder="Título"
          v-model='nota.title'
        />
        <CTextarea
          class="notas-tracking-textarea"
          placeholder="Descripción"
          v-model='nota.description'
        />
      </CCol>
      <CCol col="4" xl="4">
      </CCol>
    </CRow>
    <div slot="footer" class="w-100">
        <CButton color="secondary" square size="sm" @click="close">
          Cancelar
        </CButton>
        <CButton
            :disabled="!nota.title || !nota.description"
            class="ml-2"
            color="success"
            square
            size="sm"
             @click="saveNote()"
        >
        Guardar Nota
        </CButton>
      </div>
</CModal>
</template>

<script>
import {createNote} from '../../api/notes'

export default {
  props: ['userID', 'openModalAddNote', 'close', 'getUserNotes'],
    data () {
        return {
        nota: {
            title: '',
            description: '',
            type: 1
        },
        }
    },
    methods: {
    saveNote(){
      const self = this
      const data = {
        user_id: this.userID,
        title: this.nota.title,
        description: this.nota.description
      }
      createNote(data)
        .then(function(resp){
          self.nota = {
            title: '',
            description: '',
            type: 1
          }
            self.close()
            self.getUserNotes(self.userID)
        })
        .catch(function(error){
          console.log(error);
        })
    },
    },
}
</script>

<style lang="scss" scoped>

</style>