<template>
<div style="width: 100%;margin: 0; ">
        <nav class="Ficha__Nav">
            <div :style="[module == 1 ? {color: '#FFFF', borderBottom: '1px solid #FFFF'} : null]" @click="setModule(1)" class="Ficha__Nav__Item">Antedecentes</div>
            <div :style="[module == 2 ? {color: '#FFFF', borderBottom: '1px solid #FFFF'} : null]" @click="setModule(2)" class="Ficha__Nav__Item">Eventos Clínicos</div>
        </nav>

        
        <CreateMedicalRecord style="padding: 4px;" v-show="module == 1" :disabled="false" :user_id="pacienteData.id" />
        <Events :usersView="true" v-show="module == 2" :user_id="pacienteData.id" />
</div>
</template>

<script>
import Events from './Events.vue'
import CreateMedicalRecord from '../atencionesGeneral/CreateMedicalRecord.vue'

export default {
    props: ['pacienteData'],
    components: { Events, CreateMedicalRecord },
    data () {
        return { 
            module: 1,
        }
    },
    methods: {
        setModule(module) {
            this.module = module
        },
    },
}
</script>

<style lang="scss" scoped>
  .Ficha {
    &__Nav {
    padding-left: 4px;
    padding-bottom: 4px;
    display: flex;
    gap: 20px;
    background-color: #0064ED;
    width: 100%;
    &__Item {
        color: #FFFF;
        font-size: 18px;
        font-weight: 400;
        &:hover {
          color: #FFFF;
          cursor: pointer;
        }
      }
    }
  }
</style>