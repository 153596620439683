var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CModal',{staticClass:"modal-chart",attrs:{"title":"Test Holter","color":"primary","show":_vm.modalGraficos,"closeOnBackdrop":false},on:{"update:show":function($event){_vm.modalGraficos=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',{staticClass:"mb-0 text-center w-100"},[_vm._v("Gráfico Presión")]),_c('CButton',{staticClass:"mx-2 p-0",on:{"click":function($event){return _vm.closeModalGraficos()}}},[_c('p',{staticClass:"text-white mb-0 font-weight-bold"},[_vm._v("X")])])]},proxy:true},{key:"footer",fn:function(){return [_c('div')]},proxy:true}])},[_c('CRow',{staticStyle:{"margin-bottom":"120px"}},[_c('CChartLine',{staticClass:"my-2",staticStyle:{"height":"300px","width":"100%","max-width":"800px","margin":"0 auto"},attrs:{"datasets":[
              {
                data: _vm.data.map(item => item.sistolica).reverse(),
                borderColor: '#00BCD4',
                pointRadius: 5,
                fill: false,
                label: 'Sistólica'
              },
              {
                data: _vm.data.map(item => item.diastolica).reverse(),
                borderColor: '#2196F3',
                pointRadius: 5,
                fill: false,
                label: 'Diastólica'
              }
            ],"labels":_vm.labels}})],1),_c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}})],1),_c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('CButton',{staticClass:"w-100",attrs:{"color":"primary"},on:{"click":_vm.closeModalGraficos}},[_vm._v("Cerrar")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }