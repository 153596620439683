<template>
  <CRow class="ficha-paciente-container">
    <CCol col="12" xl="12">
      <CToaster :autohide="3000">
        <template v-for="(toast, index) in toasts">
          <CToast
            :key="'toast' + index"
            :show="true"
            :header="toast.title"
          >
            {{toast.message}}
          </CToast>
        </template>
      </CToaster>
      <transition name="slide">
        <CCard>
          <CCardHeader style="background: linear-gradient(180deg, rgba(0,100,237,1) 52%, rgba(0,212,255,1) 100%); background-position-y: -120px; background-repeat: no-repeat;">
            <div class="Ficha__Header">
              <div>
                <div style="display:flex; align-items:center; gap: 12px;">
              <div class="Ficha__Header__Avatar">
              <img
                v-if="pacienteData.info.avatar"
                style="width: 100px;height: 100px;"
                :src="pacienteData.info.avatar"
                class="c-mb-2"
              />
              <div class="icon-user" v-else><font-awesome-icon style="font-size: 50px;" icon="user"/></div>
              </div>
              <div style="display:flex;"> 
                <div>
                  <h1 class="Ficha__Header__Title" v-if="pacienteData">{{pacienteData.name}}</h1>
                  <p class="Ficha__Header__Subtitle" v-if="pacienteData">{{pacienteData.email}}</p>
                </div>
              </div>
                <CButton
                  style="display:flex; align-items:center; gap: 8px;"
                  color="success"
                  size="sm"
                  class="m-1"
                  @click="openModalVideoCall()"
                  >Inciar Videollamada 
                  <font-awesome-icon icon="phone" />
                </CButton>
              </div>
                </div>

              <div>
                <button @click="$router.push('/users')" class="btn-return">Volver</button>
              </div>
            </div>
          </CCardHeader>
          <CCardBody>

          <nav class="Ficha__Nav">
            <div :style="[module == 1 ? {color: '#5151F9', borderBottom: '1px solid #5151F9'} : null]" @click="setModule(1)" class="Ficha__Nav__Item">Datos Usuario</div>
            <div :style="[module == 2 ? {color: '#5151F9', borderBottom: '1px solid #5151F9'} : null]" @click="setModule(2)" class="Ficha__Nav__Item">Ficha Médica</div>
            <div :style="[module == 3 ? {color: '#5151F9', borderBottom: '1px solid #5151F9'} : null]" @click="setModule(3)" class="Ficha__Nav__Item">Mediciones</div>
            <div :style="[module == 4 ? {color: '#5151F9', borderBottom: '1px solid #5151F9'} : null]" @click="setModule(4)" class="Ficha__Nav__Item">Holters</div>
            <div v-if="checkPermissions(['view_recetas'])" :style="[module == 5 ? {color: '#5151F9', borderBottom: '1px solid #5151F9'} : null]" @click="setModule(5)" class="Ficha__Nav__Item">Recetas</div>
            <div v-if="checkPermissions(['view_examenes'])" :style="[module == 6 ? {color: '#5151F9', borderBottom: '1px solid #5151F9'} : null]" @click="setModule(6)" class="Ficha__Nav__Item">Exámenes</div>
            <div :style="[module == 7 ? {color: '#5151F9', borderBottom: '1px solid #5151F9'} : null]" @click="setModule(7)" class="Ficha__Nav__Item">Notas</div>
          </nav>

            <div 
              v-if="dataLoading"
              class="d-flex flex-column justify-content-center align-items-center" style="min-height: 300px;">
              <CSpinner
                style="width:4rem;height:4rem;"
                color="primary"
              />
              <p class="text-secondary my-2">Cargando datos ...</p>
            </div>
            <CRow v-else>
              <CCol col="12" xl="12">
                    <div class="ficha-paciente-card" style="padding-top: 0;" v-show="module == 1">
                      <h4>Datos Usuario</h4>
                      <div class="user-data" v-if="pacienteData && pacienteData.info">
<!--                         <img
                          v-if="pacienteData.info.avatar"
                          style="width: 100px;height: 100px;border-radius: 100px;"
                          :src="pacienteData.info.avatar"
                          class="c-mb-2"
                        />
                        <div class="icon-user" v-else><font-awesome-icon icon="user"/></div> -->
                        <updateUser :pacienteData="pacienteData" :getUserData="getUserData" :user_id="$route.params.id"/>
<!--                         <div class="datos-usuario">
                          <p class="mb-0"><span style="font-weight: 500;">Nombre: </span>{{pacienteData?.name}}</p>
                          <p class="mb-0"><span style="font-weight: 500;">Email: </span>{{pacienteData?.email}}</p>
                          <p class="mb-0"><span style="font-weight: 500;">Teléfono: </span>{{pacienteData?.info?.telefono}}</p>
                          <p class="mb-0"><span style="font-weight: 500;">RUT: </span>{{pacienteData?.info?.rut}}</p>
                          <p class="mb-0"><span style="font-weight: 500;">Dirección: </span>{{pacienteData?.info?.telefono}}</p>
                          <p v-if="pacienteData?.info?.nacimiento" class="mb-0"><span style="font-weight: 500;">Fecha nacimiento: </span>{{pacienteData?.info?.nacimiento}} ({{pacienteData?.info?.nacimiento | age}} años)</p>
                          <p class="mb-0"><span style="font-weight: 500;">Género: </span>{{pacienteData?.info?.genero === 1 ? 'Masculino':'Femenino'}}</p>
                        </div> -->
                      </div>
                    </div>



                    <div class="ficha-paciente-card" v-show="module == 2">
                      <h4 class="mb-0">Ficha Médica</h4>
                      <div class="user-data" style="margin:0 ;padding: 0; width: 100%;">
                        <FichaMedica :pacienteData="pacienteData" />
<!--                  <div class="icon-user"><font-awesome-icon icon="notes-medical" /></div>
                        <div class="datos-usuario">
                          <p class="mb-0"><span style="font-weight: 500;">Estatura: </span>{{pacienteData.ficha.estatura}} centímetros</p>
                          <p class="mb-0"><span style="font-weight: 500;">Peso: </span>{{pacienteData.ficha.peso}} kilos</p>
                        </div>
                        <div class="datos-enfermedades text-center justify-content-center d-flex flex-column">
                          <h6 class="mb-0">Enfermedades Preexistentes</h6>
                          <p class="mb-0" v-for="enfermedad in pacienteData.ficha.enfermedades">{{enfermedad}}</p>
                        </div> -->
                      </div>
                    </div>


                    <div class="ficha-paciente-card" v-show="module == 3">
                      <h4>Mediciones Smartbands</h4>
                      <div style="display: flex;justify-content: flex-end;margin-right: 20px; gap: 12px;">
                        <CButton @click="modalGraficoPresion  = true;" color="primary" variant="outline">Gráfico Presión</CButton>
                        <CButton @click="modalGraficoFrecuencia  = true;" color="primary" variant="outline">Gráfico Frecuencia</CButton>
                        <CButton @click="modalGraficoOxigeno  = true;" color="primary" variant="outline">Gráfico Oxígeno</CButton>
                        <CButton @click="modalGraficoTemperatura  = true;" color="primary" variant="outline">Gráfico Temperatura</CButton>
                      </div>
                      <CDataTable
                        class="p-3"
                        style="font-size: 12px"
                        v-show="!dataLoading"
                        hover
                        striped
                        :items="mediciones"
                        :fields="fields"
                        :items-per-page="pagination.perPage"
                        index-column
                      >
                        <template #created_at="data">
                          <td>{{data.item.created_at | filterDate}}</td>
                        </template>
                      </CDataTable>
                      <!-- <Pagination :data="pagination" :callback="paginate"/> -->
                    </div>



                    <div class="ficha-paciente-card"  v-show="module == 4">
                      <h4>Acciones</h4>
                      <div class="d-flex justify-content-center flex-column p-3">
                        <h5 class="text-center">Configurar Test Holter</h5>
                        <p class="text-center">Al configurar un test holter se activarán las mediciones automáticas de presión, frecuencia cardíaca y saturación de oxígeno en la Smartband del usuario. Esta acción no sobreescribe la configuración automática generada por el usuario desde su aplicación. El usuario puede cancelar este Test si lo desea desde la configuración de las mediciones automáticas de la aplicación.</p>
                        <p class="text-center">Es recomendable contactar al usuario antes de configurar este test, ya que el usuario debe estar usando su Smartband y debe tener su teléfono cerca para poder enviar las mediciones al servidor en todo momento.</p>
                        <p class="text-center">De todas formas se enviará una notificación al teléfono del usuario con la información que configures en el holter. Además enviaremos una notificación 10 minutos antes de comenzar el Test Holter para que el paciente pueda prepararse.</p>
                        <CRow>
                          <CCol col="4" xl="4">
                            <p class="mb-0">Intervalo de medición</p>
                            <v-select
                              class="my-2"
                              placeholder="Cada cuanto tiempo deseas tomar las mediciones"
                              :options="[
                                {id: 1, name: 'cada 30 minutos'},
                                {id: 2, name: 'cada 1 hora'},
                                {id: 3, name: 'cada 6 horas'},
                                {id: 4, name: 'cada 12 horas'},
                                {id: 5, name: '1 vez al día'},
                              ]"
                              :reduce="data => data.id"
                              label="name"
                              :clearable="false"
                              v-model="holterConfig.timerange"
                              ></v-select>
                          </CCol>
                          <CCol col="4" xl="4">
                            <p class="mb-0">Fecha y hora de inicio</p>
                            <datetime 
                              width="100%"
                              class="my-2 datepicker-iduam"
                              type="datetime"
                              :min-datetime="minDate"
                              :minute-step="30"
                              v-model="holterConfig.start"></datetime>
                          </CCol>
                          <CCol col="4" xl="4">
                            <p class="mb-0">Fecha y hora de término</p>
                            <datetime 
                              width="100%"
                              class="my-2 datepicker-iduam"
                              type="datetime"
                              :min-datetime="holterConfig.start"
                              :minute-step="30"
                              v-model="holterConfig.end"></datetime>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol col="12" xl="12">
                            <CButton
                              color="primary"
                              class="py-2 my-1 w-100"
                              @click="createHolter"
                              size="sm"
                            >
                              Programar Test Holter
                            </CButton>
<!--                             <CButton
                              color="primary"
                              class="py-2 my-1 w-100"
                              @click="startHolter"
                              size="sm"
                            >
                              Start Test Holter
                            </CButton>
                            <CButton
                              color="primary"
                              class="py-2 my-1 w-100"
                              @click="stopHolter"
                              size="sm"
                            >
                              Stop Test Holter
                            </CButton> -->

                          </CCol>
                        </CRow>
                      </div>
                        <div>
                            <h5 class="my-2 py-2 text-center holters-programados-title">
                              Holters Programados
                              <CButton
                                color="primary"
                                size="sm"
                                class="m-1"
                                @click="() => {this.showHoltersProgramados = !this.showHoltersProgramados}"
                              >
                                <font-awesome-icon :icon="showHoltersProgramados ? 'chevron-up':'chevron-down'" />
                              </CButton>
                            </h5>
                            <CDataTable
                              class="p-3"
                              style="font-size: 12px"
                              v-show="!dataLoading && showHoltersProgramados"
                              hover
                              striped
                              :items="pacienteData.holters"
                              :fields="fieldsHolters"
                              :items-per-page="pagination.perPage"
                              index-column
                            >
                              <template #status="data">
                                <td>{{data.item | filterStatus}}</td>
                              </template>
                              <template #start="data">
                                <td>{{data.item.start | filterDate}}</td>
                              </template>
                              <template #end="data">
                                <td>{{data.item.end | filterDate}}</td>
                              </template>
                              <template #timerange="data">
                                <td>{{data.item.timerange | filterTimerange}}</td>
                              </template>
                              <template #actions="data">
                                <td class="py-0 d-flex justify-content-center align-item-center ">
                                  <CButton
                                    v-c-tooltip="{content: 'Visualizar', placement: 'left'}"
                                    color="info"
                                    size="sm"
                                    class="m-1"
                                    @click="showModalHolterData(data.item.id)"
                                    v-if="data.item.status === 2"
                                  >
                                    <font-awesome-icon icon="notes-medical" />
                                  </CButton>
                                  <CButton
                                    v-c-tooltip="{content: 'Eliminar Holter', placement: 'left'}"
                                    color="danger"
                                    size="sm"
                                    class="m-1"
                                    @click="destroyHolter(data.item.id)"
                                    v-if="!data.item.deleted_at && data.item.status !== 2"
                                  >
                                    <font-awesome-icon icon="trash-alt" />
                                  </CButton>
                                </td>
                              </template>
                            </CDataTable>
                            </div>
                    </div>

                    <div v-show="module == 5">
                      <CDataTable
                        class="p-3"
                        style="font-size: 12px"
                        v-show="!dataLoading"
                        hover
                        striped
                        :items="recetas_list"
                        :fields="recetas_fields"
                        :items-per-page="pagination.perPage"
                        index-column
                      >
                        <template #created_at="data">
                          <td>{{data.item.created_at | filterDate}}</td>
                        </template>
                        <template #farmacos="data">
                          <td>
                            <CButton
                              v-c-tooltip="'Ver Detalle'"
                              color="info"
                              size="sm"
                              class="m-1"
                              @click="farmacoDetails(data.item.farmacos)"
                              >
                              <font-awesome-icon icon="info-circle" />
                            </CButton>
                            <CButton
                              v-c-tooltip="'Reenviar Receta'"
                              color="info"
                              size="sm"
                              class="m-1"
                              @click="openModalSendRecipe(data.item)"
                            >
                              <img style="width: 20px;padding-bottom: 2px;" src="../../assets/imgicons/ICONOS SOSRecurso 27.png" alt="">
                            </CButton>
                          </td>
                        </template>
                      </CDataTable>
                    </div>

          <div v-show="module == 6">
              <CDataTable
                size="sm"
                class="px-3 text-center"
                style="font-size: 12px"
                hover
                striped
                :items="examenes_list"
                :fields="fields_examenes_list"
                index-column
              >
                <template #date="data">
                    <td>{{data.item.created_at | filterDate}}</td>
                </template>
                <template #doctor="data">
                    <td>{{data.item.doctor.name}}</td>
                </template>
                <template #examenes="data">
                    <td>
                        <div v-for="examen in data.item.examenes" :key="examen.id" style="display:flex; gap: 12px; justify-content: center;">
                            <p>{{examen?.tipo?.nombre}}</p>
                            <p>{{examen?.especificacion}}</p>
                        </div>
                    </td>
                </template>
                <template #action="data">
                    <td>
                      <CButton
                        v-c-tooltip="'Reenviar Examen'"
                        color="info"
                        size="sm"
                        class="m-1"
                        @click="openModalExamen(data.item)"
                        >
                        <img style="width: 20px;padding-bottom: 2px;" src="../../assets/imgicons/ICONOS SOSRecurso 27.png" alt="">
                      </CButton>
                    </td>
                </template>
              </CDataTable>
              </div>

          <div v-show="module == 7">
            <h4>Notas</h4>
            <div class=" text-center my-3">
              <CButton
                style="width: 150px;height: 21px;display: flex;justify-content: center;align-items: center; gap: 12px;"
                @click="openModalNote"
                color="primary"
                class="mb-2 mr-2"
                >Añadir Nota <font-awesome-icon icon="plus" style="font-size: 12px"/>
              </CButton>

            </div>
              <div v-if="notes.length">
                <div style="background-color: #EBEDEF; padding: 8px; max-width: 600px;" v-for="note in notes" :key="note.id" class="mt-3">
                  <div style="display:flex; justify-content:space-between;">
                    <p class="text-left mb-1"><span class="font-weight-bold">Título:</span> {{note?.title}}</p>
                    <p style="font-style: italic;">{{note?.created_at | filterDate}}</p>
                  </div>
                  <p class="text-left mb-1"><span class="font-weight-bold">Mensaje:</span> {{note?.description}}</p>
                  <div class="d-flex justify-content-end">
                    <p class="ml-auto" style="font-style: italic;">{{note?.admin?.name}}</p>
                  </div>
                </div>
                </div>
              <div v-else>
                <p >No hay notas</p>
              </div>
          </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </transition>
      <modalHolterData :showModal="showHolterData" :holterID="showingHolterID" :closeModal="closeModalHolterData"/>


      <ModalGraficoPresion :modalGraficos="modalGraficoPresion" :data="mediciones" :closeModalGraficos="closemodalGraficoPresion"/>
      <ModalGraficoFrecuencia :modalGraficos="modalGraficoFrecuencia" :data="mediciones" :closeModalGraficos="closemodalGraficoFrecuencia"/>
      <ModalGraficoOxigeno :modalGraficos="modalGraficoOxigeno" :data="mediciones" :closeModalGraficos="closemodalGraficoOxigeno"/>
      <ModalGraficoTemperatura :modalGraficos="modalGraficoTemperatura" :data="mediciones" :closeModalGraficos="closemodalGraficoTemperatura"/>


      <ModalVideoCall :user_is_in_attention="user_is_in_attention" :modalVideoCall="modalVideoCall" :close="closeVideoCallModal" :user_id="pacienteData?.id" />

      <CModal title="Fármacos" color="primary" class="modal-sm" :show.sync="modalFarmacos">
        <div>
          <CDataTable
            size="sm"
            class="px-3 text-center"
            style="font-size: 12px"
            hover
            striped
            :items="detail_farmacos"
            :fields="fields_farmacos"
            index-column
          >
          </CDataTable>
        </div>
        <div slot="footer">
        </div>
      </CModal>

      <CModal title="Fármacos" color="primary" class="modal-sm" :show.sync="modalSendRecipe">
    <div>
      <p class="text-center">Reenviar Receta</p>
      <CDataTable
        size="sm"
        class="px-3 text-center"
        style="font-size: 12px"
        hover
        striped
        v-if="recipeToSend"
        :items="recipeToSend.farmacos"
        :fields="fields_farmacos"
        index-column
      >
      </CDataTable>
    </div>
    <div slot="footer" style="display:flex; gap: 8px;">
      <CButton color="secondary" @click="modalSendRecipe = false">Cancelar</CButton>
      <CButton color="success" @click="sendRecipe">Reenviar Receta</CButton>
    </div>
    </CModal>

    <CModal title="Reenviar Examen" color="primary" class="modal-sm" :show.sync="modalSendExamen">
      <div slot="footer" style="display:flex; gap: 8px;">
        <CButton color="secondary" @click="modalSendExamen = false">Cancelar</CButton>
        <CButton color="success" @click="sendExamen">Reenviar Examen</CButton>
      </div>
    </CModal>
    </CCol>

    <ModalAddUserNote
      :openModalAddNote="modalAddNote"
      :close="closeNoteModal"
      :userID="pacienteData?.id"
      :getUserNotes="getNotes"
    />
  </CRow>
</template>

<script>
import Pagination from '../../helpers/Pagination'
import checkPermissions from '../../helpers/userPermissions'
import {getDetails} from '../../api/user'
import {createUserHolter,deleteHolter,startUserHolter,stopUserHolter} from '../../api/holter'
import { checkUserInAttention } from '../../api/atenciones'
import vSelect from 'vue-select'
import moment from 'moment'
import modalHolterData from './modalHolterData'
import ModalGraficoPresion from './ModalGraficoPresion'
import ModalGraficoFrecuencia from './ModalGraficoFrecuencia'
import ModalGraficoOxigeno from './ModalGraficoOxigeno'
import ModalGraficoTemperatura from './ModalGraficoTemperatura'
import FichaMedica from './FichaMedica'
import ModalVideoCall from '../atenciones/ModalVideoCall.vue'
import ModalAddUserNote from './ModalAddUserNote.vue'
import { getRecetasList, sendRecetaByMail } from '../../api/recetas'
import { getExamenesList, sendMailExamen } from '../../api/examenes'
import updateUser from './updateUser.vue'
import { getNotes } from '../../api/notes'

export default {
  name: 'FichaClinica',
  components: {Pagination, 'v-select': vSelect, modalHolterData, ModalGraficoPresion, ModalGraficoFrecuencia, ModalGraficoOxigeno, ModalGraficoTemperatura, ModalVideoCall, FichaMedica, updateUser, ModalAddUserNote},
  props: ['paciente'],
  filters: {
    filterStatus: function(data){
      if(data.deleted_at){
        return 'Finalizado'
      }
      else if(data.status === 2){
        return 'En curso'
      }
      return 'Programado'
    },
    filterDate: function(date){
      return moment(date).format("DD-MM-YYYY HH:mm")
    },
    filterTimerange: function(index){
      const timerange = [
        'cada 30 minutos',
        'cada 1 hora',
        'cada 6 horas',
        'cada 12 horas',
        '1 vez al día',
      ]
      return timerange[index - 1]
    },
    age: function(date){
      const birthdate = moment(date);
      return moment().diff(birthdate, 'years');
    },
  },
  data: () => {
    return {
      notes: [],
      modalAddNote: false,
      detail_farmacos: [],
      fields_farmacos: [{ key: 'farmaco', label: 'Fármaco' },
        { key: 'indicaciones', label: 'Indicación' }],
      modalFarmacos: false,
      recetas_list :[],
      recetas_fields: [
        { key: 'valid_until', label: 'Válida hasta' },
        { key: 'indications', label: 'Indicaciones' },
        { key: 'farmacos', label: 'Fármacos' },
      ],
      modalFarmacos: false,
      detail_farmacos: [],
      pacienteData: null,
      dataLoading: true,
      pacienteID: null,
      showHolterData: false,
      showingHolterID: null,
      showHoltersProgramados: true,
      pacienteData: null,
      fields: [
        { key: 'created_at', label: 'Fecha Medición' },
        { key: 'sistolica', label: 'Presión Sistólica' },
        { key: 'diastolica', label: 'Presión Diastólica' },
        { key: 'ritmo', label: 'Frecuencia Cardíaca' },
        { key: 'oxigeno', label: 'Saturación Oxigeno' },
        { key: 'temperatura', label: 'Temperatura' },
      ],
      fieldsHolters: [
        { key: 'status', label: 'Estado' },
        { key: 'start', label: 'Inicio' },
        { key: 'end', label: 'Término' },
        { key: 'timerange', label: 'Intervalo' },
        { key: 'actions', label: 'Acción' }
      ],
      toasts: [],
      minDate: moment().add(30 - (moment().minute() % 30), 'm').set({second:0}).toISOString(),
      holterConfig: {
        timerange: 1,
        start: moment().add(30 - (moment().minute() % 30), 'm').set({second:0}).toISOString(),
        end: moment().add(30 - (moment().minute() % 30), 'm').add(1, 'd').set({second:0}).toISOString(),
      },
      pagination: {
        page: 1,
        perPage: 10,
        total: null
      },
      module: 1,
      modalGraficoPresion: false,
      modalGraficoFrecuencia: false,
      modalGraficoOxigeno: false,
      modalGraficoTemperatura: false,
      mediciones: [],
      user_is_in_attention: null,
      modalVideoCall: false,
      examenes_list: [],
      fields_examenes_list: [
        { key: 'date', label: 'Fecha' },
        { key: 'doctor', label: 'Doctor' },
        { key: 'examenes', label: 'Exámenes' },
        { key: 'action', label: '' },
      ],
      modalSendRecipe: false,
      recipeToSend: null,
      modalSendExamen: false,
      examenToSend: null
    }
  },
  mounted(){
    this.pacienteID = this.$route.params.id
    this.getUserData(this.$route.params.id)
    this.getRecetasList(this.$route.params.id)
    this.getExamenesList(this.$route.params.id)
    this.getNotes()
  },
  methods: {
    checkPermissions,
    openModalSendRecipe(recipe) {
      this.modalSendRecipe = true
      this.recipeToSend = recipe
    },
    getNotes() {
      const data = {user_id: this.$route.params.id}
      getNotes(data).then(res => {
        console.log(res);
        this.notes = res.notes
        
      })
    },
    sendRecipe() {
      const data = {receta_id: this.recipeToSend.id}
      sendRecetaByMail(data).then(res => {
        this.modalSendRecipe = false
      this.toasts.push({
        title: 'Receta Enviada',
        })
      }).catch(e => {
        this.modalSendRecipe = false
        console.log(e);
          this.toasts.push({
          title: 'Error',
        })
      })
    },
    openModalExamen(examen) {
      this.modalSendExamen = true
      this.examenToSend = examen
    },
    sendExamen() {
      const data = {examen_id: this.examenToSend.id}
      sendMailExamen(data).then(res => {
        this.modalSendExamen = false
        this.toasts.push({
          title: 'Receta Enviada',
        })
      }).catch(e => {
        console.log(e);
        this.modalSendExamen  = false
          this.toasts.push({
          title: 'Error',
        })
      })
    },
    showModalHolterData(holterID){
      console.log(holterID)
      this.showHolterData = true
      this.showingHolterID = holterID
    },
    closeModalHolterData(){
      this.showHolterData = false
      this.showingHolterID = null
    },
    async getUserData(userID){      
      const userData = await getDetails(userID)
      console.log(userData)
      this.dataLoading = false
      if(userData.code === 200){
        this.pacienteData = userData.user
        this.mediciones = userData.user.mediciones
      }
    },
    paginate(page, perPage){
      this.pagination = {
        page: page,
        perPage: perPage,
        total: this.pagination.total
      }
      this.getMediciones()
    },
    getMediciones(){
      const data = {
        limit: this.pagination.perPage,
        page: this.pagination.page
      }
      const self = this
      this.loading = true
    },
    createHolter(){
      const data = {
        user_id: this.pacienteID,
        timerange: this.holterConfig.timerange,
        start: moment( this.holterConfig.start ).format("YYYY/MM/DD HH:mm"),
        end: moment( this.holterConfig.end ).format("YYYY/MM/DD HH:mm"),
      }
      const self = this
      createUserHolter(data)
        .then(function(resp){
          self.toasts.push({
            title: 'Test Holter Creado',
            message: 'Se ha creado el test holter, hemos enviado una notificación al usuario.'
          })
          self.getUserData(self.$route.params.id)
        })
        .catch(function(error){
          console.log(error)
          self.toasts.push({
            title: 'Error',
            message: 'Hubo un error al crear el test holter, por favor recarga la página e intenta nuevamente.'
          })
        })
    },
    destroyHolter(holterID){
      const self = this
      deleteHolter({id: holterID})
        .then(function(resp){
          console.log("R: ", resp)
          self.getUserData(self.$route.params.id)
        })
        .catch(function(error){
          console.log(error)
        })
    },
    startHolter(){
      startUserHolter()
        .then(function(resp){
          console.log("R: ", resp)
        })
        .catch(function(error){
          console.log(error)
        })
    },
    stopHolter(){
      stopUserHolter()
        .then(function(resp){
          console.log("R: ", resp)
        })
        .catch(function(error){
          console.log(error)
        })
    },
    setModule(module) {
      this.module = module
    },
    closemodalGraficoPresion() {
      this.modalGraficoPresion = false
    },
    closemodalGraficoFrecuencia() {
      this.modalGraficoFrecuencia = false
    },
    closemodalGraficoOxigeno() {
      this.modalGraficoOxigeno = false
    },
    closemodalGraficoTemperatura() {
      this.modalGraficoTemperatura = false
    },
    openModalVideoCall() {
      checkUserInAttention({user_id: this.pacienteData?.id}).then(res => {
        this.modalVideoCall = true;
        this.user_is_in_attention = res.user_is_in_attention
      }).catch(e => {
        console.log(e);
      })
    },
    closeVideoCallModal() {
      this.modalVideoCall = false;
    },
    getRecetasList(user_id) {
      const data = {user_id}
      getRecetasList(data).then(res => {
        this.recetas_list = res.recetas
      }).catch(e => {
        console.log(e);
      })
    },
    farmacoDetails(farmacos) {
      this.detail_farmacos = farmacos;
      this.modalFarmacos = true;
    } ,
    getExamenesList(user_id) {
      const data = { user_id }
      getExamenesList(data).then(res => {
        this.examenes_list = res.recetas
      })
    },
    getUserNotes() {
      const data = { user_id: this.pacienteData?.id }
      getUserNotes(data).then(res => {
 /*        this.notes = res.recetas */
      })
    },
    openModalNote() {
      this.modalAddNote = true
    },
    closeNoteModal() {
      this.modalAddNote = false
    }
  }
  
}
</script>

<style lang="scss" scoped>
  .Ficha {
    &__Header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      height: 150px;
      &__Avatar {
        width: 120px;
        height: 120px;
        border-radius: 15px;
        background: #F0F0FF;
        display:flex;
        justify-content:center;
        align-items:center;
      }
      &__Title {
        font-weight: 500;
        font-size: 25px;
        margin-bottom: 0;
      }
      &__Subtitle {
        color: #9499A1;
        margin-bottom: 0;
      }
    }
    &__Nav {
      display: flex;
      gap: 20px;
      &__Item {
        color: #9499A1;
        font-size: 18px;
        font-weight: 400;
        &:hover {
          color: #5151F9;
          cursor: pointer;
        }
      }
    }
  }
  .btn-return {
    border-radius: 15px;
    background-color: #5151F9;
    color: #FFF;
    border: none;
    padding: 8px 12px;
  }
</style>