<template>
    <div>
    <CModal
      title="Test Holter"
      color="primary"
      class="modal-chart"
      :show.sync="modalGraficos"
      :closeOnBackdrop="false"
    >
      <template #header>
        <p class="mb-0 text-center w-100">Gráfico Presión</p>
        <CButton
          class="mx-2 p-0"
          @click="closeModalGraficos()"
        >
          <p class="text-white mb-0 font-weight-bold">X</p>
        </CButton>
      </template>
      <CRow style=" margin-bottom: 120px;">
        <CChartLine
                class="my-2"
                style="height:300px; width: 100%;max-width: 800px; margin: 0 auto;"
                :datasets="[
                  {
                    data: data.map(item => item.sistolica).reverse(),
                    borderColor: '#00BCD4',
                    pointRadius: 5,
                    fill: false,
                    label: 'Sistólica'
                  },
                  {
                    data: data.map(item => item.diastolica).reverse(),
                    borderColor: '#2196F3',
                    pointRadius: 5,
                    fill: false,
                    label: 'Diastólica'
                  }
                ]"
                :labels="labels"
              />
      </CRow>
      <CRow>
        <CCol col="12" xl="12">
        </CCol>
      </CRow>
      <CRow>
        <CCol col="12" xl="12">
          <CButton @click="closeModalGraficos" color="primary" class="w-100">Cerrar</CButton>
        </CCol>
      </CRow>
      <template #footer>
        <div></div>
      </template>
    </CModal>
    </div>
</template>

<script>
export default {
    props: ['modalGraficos', 'data', 'closeModalGraficos'],
    data () {
        return {

        }
    },
    computed: {
        labels() {
            return this.data.map(medicion => medicion.updated_at).reverse()
        }
    },
}
</script>

<style lang="scss" scoped>

</style>